import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import Layout from '../../components/layout';

const La = styled(Layout)``;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const Room = ({ id }) => {
  useEffect(() => {
    window.location.replace(`https://opentokdemo.tokbox.com/room/${id}`);
  }, [id]);

  return (
    <La background="white">
      <Container>
        <CircularProgress size={50}></CircularProgress>
      </Container>
    </La>
  );
};

export default Room;
